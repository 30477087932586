import * as React from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import "../css/Plans.css";
import ModalformBasicApp from "../components/Modal/app-dev/Modal-App-Basic";
import ModalformStandardApp from "../components/Modal/app-dev/Modal-App-Standard";
import ModalformPremiumApp from "../components/Modal/app-dev/Modal-App-Premium";
import AppCards from "../components/AppCards";
import MobileLottieAnimation from "../components/MobileAnimation";
import Typewriter from "../components/TypeWriter.jsx";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import ConsultationBanner from "../components/consultationBanner/consultationBanner.jsx";
import MoreServices from "../components/moreServices/moreServices.jsx";
import PricingCards from "../components/pricingCards/priceCards.jsx";
import ServiceForm from "../components/ServiceForm.jsx";
import ContactForm from "../components/ContactForm.jsx";
import WebAgency from "../components/webAgency/webAgency.jsx";
import ContentWritingCards from "../components/ContentWritingCards.jsx";

export default function ContentWriting({ plans }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState({ open: false, type: "" });
  const [selectedPlan, setSelectedPlan] = useState("");

  const handleCloseModal = () => setShowModal({ open: false, type: "" });

  const handleShow = (planName) => {
    setSelectedPlan(planName);
    setShowModal({ open: true, type: planName.trim() });
  };

  return (
    <>
      <ServiceForm
        title={"Professional Content Writing Services to Boost Your Brand"}
        description={
          "Softmark Solutions delivers exceptional content writing services tailored to engage your audience and enhance your online presence. Let us bring your ideas to life with words."
        }
        buttonText={"Get In Touch"}
      />

      {/* <div className="main-container container">
        <h2>
          SoftMark Solutions -{" "}
          <Typewriter strings={["Comprehensive Content Writing Services for Your Needs"]} />
        </h2>
        <div className="text-img-cont">
          <h5 className="sub-paragraph app-para">
          Softmark Solutions offers website content writing services, blog creation, SEO content, product descriptions, and more. Partner with a trusted content writing agency for quality results.
          </h5>
          <div className="mobile">
            <MobileLottieAnimation />
          </div>
        </div>
      </div> */}

      <div className="card-heading-service">
        <h2 className="heading-underline-service">
        Comprehensive Content Writing Services for Your Needs
        </h2>
        <div className="container text" style={{ marginTop: "2rem" }}>
          <p>
          Softmark Solutions offers website content writing services, blog creation, SEO content, product descriptions, and more. Partner with a trusted content writing agency for quality results.
          </p>
        </div>
      </div>

      <div className="upcards">
        <ContentWritingCards />
      </div>

      <div className="main-container container">
        <h2>Affordable Content Writing Services
        </h2>
        <h5 className="last-paragraph">
        Get premium content writing services without breaking the bank. Softmark Solutions offers engaging, high-quality content tailored to your goals at competitive rates.
        </h5>
      </div>

      <div className="plans-container container">
        <PricingCards pricingPlans={plans} />
        {/* {plans.map((plan, index) => (
          <Card className={`plan-card plan-card-${index}`} key={index}>
            <CardActionArea>
              <CardContent>
                <div className="plans-cont">
                  <div className="plan-header">
                    <span className="plan-title">{plan.name}</span>
                    <button
                      className="select-button"
                      onClick={() => handleShow(plan.name)}
                      aria-label={`Select ${plan.name} plan`} 
                    >
                      START YOUR PLAN
                    </button>
                  </div>
                  <div className="plan-features-container">
                    <ul className="plan-features">
                      {plan.features.map((feature, i) => (
                        <li key={i}>
                          <i className="fa fa-check-circle"></i> {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        ))} */}
        {/* <button
          className="contact-btn"
          style={{ padding: "20px" }}
          onClick={() => navigate("/portfolio-detail/app/App%20Development")}
        >
          Explore Our Portfolio of Mobile Applications
        </button> */}
      </div>

      {/* Conditionally render modal based on selected plan type */}
      {showModal.open && (
        <>
          {showModal.type === "Basic" && (
            <ModalformBasicApp
              isOpened={showModal.open}
              heading={`${selectedPlan} Plan`}
              handleClose={handleCloseModal}
            />
          )}
          {showModal.type === "Standard" && (
            <ModalformStandardApp
              isOpened={showModal.open}
              heading={`${selectedPlan} Plan`}
              handleClose={handleCloseModal}
            />
          )}
          {showModal.type === "Premium" && (
            <ModalformPremiumApp
              isOpened={showModal.open}
              heading={`${selectedPlan} Plan`}
              handleClose={handleCloseModal}
            />
          )}
        </>
      )}

      <ConsultationBanner
        heading={"Elevate Your Content with Expert Writers"}
        para={
          "Softmark Solutions is your go-to content writing agency. Let us create compelling, SEO-friendly content to drive traffic and captivate your audience. Contact us today!."
        }
      />
      <WebAgency
        heading={`Why Choose Softmark Solutions for Content Writing?`}
        para1={`At Softmark Solutions, we specialize in website content writing services that resonate with your audience. Whether you need blogs, product descriptions, or SEO-optimized pages, we craft content that drives results.`}
        para2={`Our team of expert writers delivers affordable content writing services tailored to your unique needs. As a leading content writing agency, we focus on quality, creativity, and value to help you stand out in the competitive digital landscape.`}
      />
      <MoreServices
        heading={"Explore More Services by Softmark Solutions"}
        para={`Softmark Solutions is more than just a content writing agency. Discover our wide range of services, including Website Development Services, App Development Services, Graphics Design Services, SEO Services, Software Development Services, Anime Art Services, and Social Media Marketing Services.
We aim to provide comprehensive solutions for all your digital needs. Whether it's creating engaging content or developing innovative software, our team is dedicated to delivering excellence across all services.
`}
      />
      <ContactForm
        heading={"Get Premium Content Writing Services Today"}
        para={`Looking for website content writing services that deliver real results? Contact Softmark Solutions now for affordable content writing services tailored to your brand. Let’s create magic with words!`}
      />
    </>
  );
}

ContentWriting.propTypes = {
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      features: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
}
